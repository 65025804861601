import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user langua
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    //backend: {loadPath: './locales/{{lng}}/{{ns}}.json'},
    resources: {
      en: {
        digitalsignatures: require('./locales/en/content/digitalsignatures.json'),
        brewing: require('./locales/en/brewing.json'),
        common: require('./locales/en/common.json'),
        contact: require('./locales/en/contact.json'),
        error: require('./locales/en/error.json'),
        header: require('./locales/en/header.json'),
        landing: require('./locales/en/landing.json'),
        links: require('./locales/en/links.json'),
        publications: require('./locales/en/publications.json')
      },
      fr: {
        digitalsignatures: require('./locales/fr/content/digitalsignatures.json'),
        brewing: require('./locales/fr/brewing.json'),
        common: require('./locales/fr/common.json'),
        contact: require('./locales/fr/contact.json'),
        error: require('./locales/fr/error.json'),
        header: require('./locales/fr/header.json'),
        landing: require('./locales/fr/landing.json'),
        links: require('./locales/fr/links.json'),
        publications: require('./locales/fr/publications.json')
      },
      de: {
        digitalsignatures: require('./locales/de/content/digitalsignatures.json'),
        brewing: require('./locales/de/brewing.json'),
        common: require('./locales/de/common.json'),
        contact: require('./locales/de/contact.json'),
        error: require('./locales/de/error.json'),
        header: require('./locales/de/header.json'),
        landing: require('./locales/de/landing.json'),
        links: require('./locales/de/links.json'),
        publications: require('./locales/de/publications.json')
      }
    },
    //ns: ['common', 'header', 'landing'],
    //defaultNS: 'common'
  });


export default i18n;
