import API from './api.js';


export default class StateFetcher extends API {
	apiURL = '';
	method = 'GET';
	defaultRequestBody = null;


	componentDidMount() {
		this.get();
	}


	getSuccessCallback(data) {
		//Placeholder. Add behaviour to perform when fetch succeeds, with data as the return data
	}


	get() {
		this.api(this.apiURL, this.method, {onSuccess:this.getSuccessCallback.bind(this), body:this.defaultRequestBody})
	}



}
