import React from 'react';
import { MarkdownCompatible, ContentPlaceholder, ContentHeader, withRouter } from 'psCMS-common';
import Markdown from 'markdown-to-jsx'
const Error = React.lazy(() => import('../error/index'));

export default withRouter(class ContentLayout extends MarkdownCompatible(React.Component) {


	findCorrespondingContent() {
		for( var cIx in this.props.content) {
			if(this.props.content[cIx].key===this.props.router.params.key && this.props.content[cIx].language===this.props.router.params.lang ) {
				return this.props.content[cIx]
			}
		}
		return null
	}


	renderSuspense() {
		return <>
			<ContentPlaceholder variety='paragraph'/>
		</>
	}


	renderContent() {
		const content = this.findCorrespondingContent();
		if(!content) {return <Error status={404} key={this.props.router.params.key} />}
		return <>
			<ContentHeader
				dateTime={new Date(content.updatedAt)}
				title={content.superTitle}
				subtitle={content.title}
			/>
			<div className="reading">
				{content.content ?
					<Markdown options={this.getMarkdownOptions()}>{content.content}</Markdown>
				:
					''
				}
			</div>
		</>
	}


	render(){
		return (
			<React.Suspense fallback={this.renderSuspense()}>
				{!this.props.content ? 
					this.renderSuspense()
				: 
					this.renderContent()
				}
			</React.Suspense>
		)
	}

})
