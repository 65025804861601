import React  from 'react';
import ContentLayout from './layout';
import DigitalSignatures from './digitalsignatures';
import { Routes, Route } from "react-router-dom";
import { API } from 'psCMS-common';

export default class Content extends API {
	
	componentDidMount() {
		if(!this.state.contents) {
			this.api('/contents/list', 'GET', {onSuccess:(data) => {this.setState(data)}});
		}
	}

	render(){
		return (
			<article>
				<Routes>
					<Route path="digitalsignatures" element={<DigitalSignatures />} />
					<Route path=":key" element={<ContentLayout content={this.state.contents}/>} />
				</Routes>
			</article>
		);
	}
}
