import React  from 'react';

export default class ContentPlaceholder extends React.Component {

	static defaultProps = {
		variety: 'custom'
	};



	render(){
		const { style={}, className='', height=null, ...otherProps} = this.props

		switch(this.props.variety) {
			case 'paragraph':
				return (
					<React.Fragment>
						<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{height:'130px', marginBottom: '40px'}}>
							<div className="load-wraper">
								<div className="activity"></div>
							</div>
						</div>
						<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{marginLeft: '100px', width: 'initial', ...style}}>
							<div className="load-wraper">
								<div className="activity"></div>
							</div>
						</div>
						<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{...style}}>
							<div className="load-wraper">
								<div className="activity"></div>
							</div>
						</div>
						<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{...style}}>
							<div className="load-wraper">
								<div className="activity"></div>
							</div>
						</div>
						<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{...style}}>
							<div className="load-wraper">
								<div className="activity"></div>
							</div>
						</div>
						<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{width: '35%', ...style}}>
							<div className="load-wraper">
								<div className="activity"></div>
							</div>
						</div>
					</React.Fragment>
				);
			default:
				return (
					<div {...otherProps} className={'suspense'+(className? ' '+ className : '')} style={{...(height? {height:height} : {}), ...style}}>
						<div className="load-wraper">
							<div className="activity"></div>
						</div>
					</div>
				);
		} 
		
	}

}
