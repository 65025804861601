import React  from 'react';
import { withTranslation } from 'react-i18next';

export default withTranslation('common')(class CodeBlock extends React.PureComponent {
  static defaultProps = {
    title: '',
    content: '',
    showStats: false,
    customClasses: '',
  };


  generateLineNumbers() {
    return Array.from({ length: this.countLines() - 1 }, (_, i) => i).join("\n")
  }


  countLines() {
    return this.props.content.split("\n").length+1;
  }


  countCharachters() {
    return this.props.content.length;
  }


  render(){
    return (
      <div className={"ps_code_block "+this.props.customClasses}>
        {this.props.title!=="" ? <pre className="ps_code_block_header">{this.props.title}</pre> : ``}
        <pre className="ps_code_block_numbering">{this.generateLineNumbers()}</pre>
        <pre><code>{this.props.content}</code></pre>
        {this.props.showStats ? <pre className="ps_code_block_footer">{this.props.t('Lines')+': '+this.countLines()+' '+this.props.t('Characters')+': '+this.countCharachters()}</pre> : ``}
      </div>
    );
  }
})
