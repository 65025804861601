import React  from 'react';
import { ContentHeader, ContentPlaceholder, API, withRouter } from 'psCMS-common';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import moment from 'moment'
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const QRCodeSVG = React.lazy(() => import('qrcode.react').then(module=>({default:module.QRCodeSVG})));

export default withTranslation('brewing')(withRouter(class Brewing extends API {


	componentDidMount() {
		this.load()
	}


	componentDidUpdate(prevProps, prevState) {
		if(prevProps.router.params['*']!==this.props.router.params['*']){
			this.load()
		}
	}


	load() {
		this.api('/brews/'+this.props.router.params['*'], 'GET', {onSuccess:(data) => {
			this.setState({brew: data.brews[0]}, ()=>{
				this.api('/brews/'+this.state.brew.batchCode+'/similarStyle', 'GET', {onSuccess:(data) => {
					this.setState(data);
				}});
				this.api('/brews/'+this.state.brew.batchCode+'/similarName', 'GET', {onSuccess:(data) => {
					this.setState(data);
				}});
			})
		}, onError: ()=>{this.props.router.navigate(`/${this.props.router.params.lang}/404`)}});
	}


	estimateABV(sifigs=1, unit='%') {
		return this.state.brew.specificGravityOG && this.state.brew.specificGravityFG ? ((this.state.brew.specificGravityOG - this.state.brew.specificGravityFG) * 131.25).toFixed(sifigs)+unit : this.props.t('n/a')
	}


	renderSuspense() {
		return <>
			<ContentPlaceholder variety='paragraph'/>
		</>
	}


	render() {
		return (
			<React.Suspense fallback={this.renderSuspense()}>
				{!this.state.brew ?
					this.renderSuspense()
				:
					<>
						<ContentHeader
							dateTime={new Date(this.state.brew.updatedAt)}
							title={this.props.t('title')}
							subtitle={this.props.t('subtitle', {batchCode: this.state.brew.batchCode})}
						/>
						<div className="reading">
							<p className="ps_pop"><Trans t={this.props.t} values={{batchCode: this.state.brew.batchCode}}>This is a data sheet for the brewing characteristics of batch no. __batchCode__.</Trans></p>
						</div>

						<div className="reading ps_pop">
							<h1 className="ps_pop" style={{marginTop: '20px'}}>{this.state.brew.name}</h1>
						</div>
						<Row className="reading ps_pop">
							<Col md={6} xs={12}>
								<ListGroup>
									<ListGroup.Item><b>{this.props.t('style')}</b> {this.state.brew.style}</ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('abv')}</b> {this.state.brew.abv}%</ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('estimatedABV')}</b> {this.estimateABV()}</ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('specificGravityOG')}</b> {this.state.brew.specificGravityOG}</ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('specificGravityFG')}</b> {this.state.brew.specificGravityFG}</ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('batchSize')}</b> {this.state.brew.batchSize} L</ListGroup.Item>
								</ListGroup>
							</Col>
							<Col md={4} sm={12} className="mt-4 mt-md-0">
								<ListGroup>
									<ListGroup.Item><b>{this.props.t('brewedOn')}</b> <OverlayTrigger
										placement="bottom"
										overlay={(props) => (
											<Tooltip {...props}>
												{moment(this.state.brew.brewedOn).format("dddd, MMMM DD, YYYY, h:mm A")}
											</Tooltip>
										)}>
										<span>{moment(this.state.brew.brewedOn).fromNow()}</span>
									</OverlayTrigger></ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('bottledOn')}</b> <OverlayTrigger
										placement="bottom"
										overlay={(props) => (
											<Tooltip {...props}>
												{moment(this.state.brew.bottledOn).format("dddd, MMMM DD, YYYY, h:mm A")}
											</Tooltip>
										)}>
										<span>{moment(this.state.brew.bottledOn).fromNow()}</span>
									</OverlayTrigger></ListGroup.Item>
									<ListGroup.Item><b>{this.props.t('readyOn')}</b> <OverlayTrigger
										placement="bottom"
										overlay={(props) => (
											<Tooltip {...props}>
												{moment(this.state.brew.readyOn).format("dddd, MMMM DD, YYYY, h:mm A")}
											</Tooltip>
										)}>
										<span>{moment(this.state.brew.readyOn).fromNow()}</span>
									</OverlayTrigger></ListGroup.Item>
								</ListGroup>
							</Col>
							<Col md={2} sm={12} className="mt-4 mt-md-0">
								<QRCodeSVG value={window.location.href} />
							</Col>
						</Row>
						<div className="reading ps_pop">
							<ListGroup>
								<ListGroup.Item><b>{this.props.t('notes')}</b> {this.state.brew.notes ? this.state.brew.notes : this.props.t('n/a')}</ListGroup.Item>
								<ListGroup.Item><b>{this.props.t('sourceRecipe')}</b> {this.state.brew.recipeURL ? <a href={this.state.brew.recipeURL}>{this.state.brew.recipeURL}</a> : this.props.t('n/a')}</ListGroup.Item>
								<ListGroup.Item><b>{this.props.t('other brews in this style')}</b> {this.state.similarStyle && this.state.similarStyle.length > 1 ? this.state.similarStyle.batchCodes.filter(code=>code!==this.state.brew.batchCode).map(code=><Link key={code} to={`/${this.props.router.params.lang}/brewing/${code}`}>{code}</Link>) : this.props.t('n/a')}</ListGroup.Item>
								<ListGroup.Item><b>{this.props.t('other batches of this recipe')}</b> {this.state.similarName && this.state.similarName.length > 1 ? this.state.similarName.batchCodes.filter(code=>code!==this.state.brew.batchCode).map(code=><Link key={code} to={`/${this.props.router.params.lang}/brewing/${code}`}>{code}</Link>) : this.props.t('n/a')}</ListGroup.Item>
							</ListGroup>
						</div>
					</>
				}

			</React.Suspense>
		);
	}
}))
