import React  from 'react';
import Downshift from 'downshift'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export default class AutocompleteText extends React.Component {
	inputFwdReference = React.createRef();

	static defaultProps = {
		value: ''
	};


	constructor(props) {
		super(props);
		this.state = {value: props.value}
	}


	componentDidUpdate(prevProps, prevState) {
		if(prevProps.value != this.props.value) {
			this.setState({value: this.props.value}) //Not sure why I have to do this manually, short normally trigger automatically.
		}
	}


	handleStateChange = (changes) => {
		if (changes.hasOwnProperty('selectedItem') && changes.selectedItem!==this.state.value) {
			this.setState({value: changes.selectedItem.value})
			this.props.onBlur(changes.selectedItem.value, this.inputFwdReference.current)
		} else if (changes.hasOwnProperty('inputValue') && changes.inputValue!==this.state.value) {
			this.setState({value: changes.inputValue}, ()=>{
				if(this.props.onChange) {
					this.props.onChange(changes.inputValue, this.inputFwdReference.current)
				}else{
					this.props.onBlur(changes.inputValue, this.inputFwdReference.current)
				}
			})
		}
	}

	render() {
		const { items, onChange, ...props } = this.props;
		return (
			<Downshift initialInputValue={this.props.value} selectedItem={this.state.value} onStateChange={this.handleStateChange}>
				{({
					getLabelProps,
					getInputProps,
					getToggleButtonProps,
					getMenuProps,
					getItemProps,
					isOpen,
					clearSelection,
					selectedItem,
					inputValue,
					highlightedIndex,
				}) => (
					<div className={'input-group form-floating'}>
						<input ref={this.inputFwdReference} {...props} {...(this.props.label ? {placeholder: this.props.label}: {})} 
							{...getInputProps({
							})}
						/>
						<label {...getLabelProps()}>{this.props.label}</label>
						<ul {...getMenuProps()} className="dropdown-menu" style={{display: 'initial', top: '58px', padding: '0'}}>
							{isOpen &&
							this.props.items
								.filter((item) => !inputValue || item.value.includes(inputValue))
								.map((item, index) => (
									<li
										className="dropdown-item"
										{...getItemProps({
											key: `${item.value}${index}`,
											item,
											index,
											/*style: {
												backgroundColor:
													highlightedIndex === index ? 'lightgray' : 'white',
												fontWeight: selectedItem === item ? 'bold' : 'normal',
											},*/
										})}
									>
										{item.value}
									</li>
								)
							)
						}
					</ul>
					<button 
						{...getToggleButtonProps()}
						className={'input-group-text'}> 
						<FontAwesomeIcon icon={solid('list')} />
					</button>
					</div>
				)}
			</Downshift>
		)
	}

}












