import React  from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Button from 'react-bootstrap/Button';

export default class Modal extends React.Component {


  static defaultProps = {
    onClose: ()=>{}
  };

  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  renderDefaultContent() {
    // Method prototype. Use this to define the default content to display (if children is empty)
    // This method is typically used to create modal fragments by extending this component.
    return ''
  }

  show() {this.setState({showModal: true});}

  render(){
    const {title, children, action, size="lg"} = this.props

    return (
      <BootstrapModal
        show={this.state.showModal}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          this.setState({showModal: false},
            ()=>{this.props.onClose()}
          )
        }}
        centered>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title id="contained-modal-title-vcenter">
            {title}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {!children ? this.renderDefaultContent() : children}
        </BootstrapModal.Body>
        <BootstrapModal.Footer style={{justifyContent: 'space-between'}}>
          <Button 
            variant="neutral"
            className="float-left"
            style={{float: 'left'}}
            onClick={() => {
              this.setState(
                {showModal: false},
                ()=>{this.props.onClose()}
              )
            }}>
            <FontAwesomeIcon icon={solid('x')} /> Close
          </Button>
          {action}
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  }

}
