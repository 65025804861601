import React from 'react';
import ExA from '../../views/basics/exa.js'
import { HashLink } from 'react-router-hash-link';

export default (superclass) => class MarkdownCompatible extends superclass {
	
	static defaultProps = {
		classNameOverride: 'ps_pop',
	};


	getMarkdownOptions() {
		return {
			forceBlock: true,
			overrides: {
				h1: {
					component: 'h1',
					props: {
						className: this.props.classNameOverride,
					},
				},
				h2: {
					component: 'h2',
					props: {
						className: this.props.classNameOverride,
					},
				},
				h3: {
					component: 'h3',
					props: {
						className: this.props.classNameOverride,
					},
				},
				h4: {
					component: 'h4',
					props: {
						className: this.props.classNameOverride,
					},
				},
				p: {
					component: 'p',
					props: {
						className: this.props.classNameOverride,
					},
				},
				ul: {
					component: 'ul',
					props: {
						className: this.props.classNameOverride,
					},
				},
				a: {
					component: MarkdownLink,
				}
			},
		}
	}

}

class MarkdownLink extends React.Component {

	render() {
		// e.g. anchors
		if(!this.props.href) {
			return <a {...this.props} />;
		// Internal navigation links
		}else if(this.props.href.startsWith('/') || this.props.href.startsWith('#')){
			return <HashLink to={this.props.href}>{this.props.children}</HashLink>;
		// Everything else presumed to be external
		}else{
			return <ExA {...this.props}/>
		}
		
	}

}
