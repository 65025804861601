export default (superclass) => class LangCore extends superclass {


	getLanguage() {
		//Expected to be run in a component with withRouter attached
		if(!this.props.router) {return 'en';}
		switch(this.props.router.params.lang) {
			case 'en':
			case 'fr':
			case 'de':
				return this.props.router.params.lang;
			default:
				return 'en';
		}
	}


	changeLanguage(targetLang, nav=true) {
		const thisPath = this.props.router.location.pathname;
		if(nav) {this.props.router.navigate(
			thisPath.endsWith('/'+this.getLanguage()) ?
			 thisPath.replace('/'+this.getLanguage(),'/'+targetLang) 
			: 
			thisPath.replace('/'+this.getLanguage()+'/','/'+targetLang+'/')
		);}
		document.documentElement.setAttribute("lang", targetLang)
		this.props.i18n.changeLanguage(targetLang);
	}

}