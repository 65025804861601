import React from 'react';
import API from './api.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export default class FileIO extends API {
	
	static defaultProps = {
		cwd: ''
	};

	//https://malcoded.com/posts/react-file-upload/
	getCommonState(props) {
		return {
			...super.getCommonState(props),
			files: {},
			mode: 'public'
		}
	}


	humanizeFileSize(fileSize) {
		if(fileSize >= 1000000) {
			return Math.round(fileSize/1000000)+' MB'
		}else if(fileSize >= 1000) {
			return Math.round(fileSize/1000)+' KB'
		}else{
			return fileSize+' Bytes'
		}
	}


	guessFileIcon(file) {
		switch(file.type) {
			case 'pdf':
				return <FontAwesomeIcon icon={solid('file-pdf')} />
			case 'png':
			case 'jpeg':
			case 'jpg':
			case 'svg':
			case 'bmp':
				return <FontAwesomeIcon icon={solid('file-image')} />
			case 'doc':
			case 'docx':
			case 'odt':
				return <FontAwesomeIcon icon={solid('file-word')} />
			case 'xls':
			case 'xlsx':
			case 'ods':
				return <FontAwesomeIcon icon={solid('file-excel')} />
			case 'csv':
				return <FontAwesomeIcon icon={solid('file-csv')} />
			case 'mp3':
			case 'ogg':
			case 'wav':
				return <FontAwesomeIcon icon={solid('file-audio')} />
			case 'mp4':
			case 'avi':
			case 'mkv':
			case 'webm':
				return <FontAwesomeIcon icon={solid('file-video')} />
			case 'txt':
				return <FontAwesomeIcon icon={solid('file-lines')} />
			case 'htm':
			case 'html':
			case 'c':
			case 'cpp':
			case 'py':
			case 'rb':
			case 'js':
			case 'css':
			case 'scss':
				return <FontAwesomeIcon icon={solid('file-code')} />
			case 'rar':
			case 'zip':
				return <FontAwesomeIcon icon={solid('file-zipper')} />
			default:
				return <FontAwesomeIcon icon={solid('file')} />
		} 
	}


	uploadCallback() {
		//Function prototype
	}


	uploadFile(fileUID) {
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();

			req.upload.addEventListener("progress", event => {
				if (event.lengthComputable) {
					var fileState = this.state.files[fileUID];
					fileState.uploadProgress = (event.loaded / event.total) * 100;
					this.setState({
						isLoading: true,
						status: null,
						files: {
							...this.state.files,
							[fileUID]: fileState
						}
					});
				}
			});

			req.upload.addEventListener("load", event => {
				var fileState = this.state.files[fileUID];
				fileState.uploadProgress = 100;
				fileState.uploading = false;
				this.setState({
					files: {
						...this.state.files,
						[fileUID]: fileState
					}
				});
				resolve(req.response);
			});

			['error', 'abort'].forEach(function(e){
				req.upload.addEventListener(e, event => {
					var fileState = this.state.files[fileUID];
					fileState.uploadProgress = 100;
					fileState.uploading = null;
					this.setState({
						files: {
							...this.state.files,
							[fileUID]: fileState
						}
					});
					reject(req.response);
				});
			})

			req.onload = () => {
				this.setState({
					isLoading: (req.status===200 ? false : "error"),
					status: req.status,
				},()=>{
					if(req.status===200){this.uploadCallback()}
				});
			};


			const formData = new FormData();
			formData.append('file', this.state.files[fileUID].file, this.state.files[fileUID].name);
			formData.append('type', this.state.files[fileUID].type);
			formData.append('path', this.props.cwd);

			req.open("POST", "/upload");
			req.send(formData);
		});
	}


	deleteFile(fileId, next=()=>{}) {
		this.api('/upload/'+fileId, 'DELETE', {onSuccess:() => {
			var files = this.state.files;
			delete files[fileId];
			this.setState({files:files}, next());
		}})
	}

}
