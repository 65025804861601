import React  from 'react';
import { ContentHeader, ContentPlaceholder, FileIO } from 'psCMS-common';
import Markdown from 'markdown-to-jsx'
import { Trans, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {parseBibFile, normalizeFieldValue} from "bibtex";

export default withTranslation('publications')(class Publications extends FileIO {

	static defaultProps = {
		highlightAuthor:'ST-AUBIN',
		sortBy: 'year',
		sortOrder: 'DESC'
	};


	componentDidMount() {
		if(!this.state.contents) {
			this.api('/publications/list', 'POST', {onSuccess:(data) => {
				//Extract sorting column
				let publications = data.publications.map((publication)=>{
					return {...publication, [this.props.sortBy]: normalizeFieldValue(parseBibFile(publication.bibtex).entries_raw[0].fields[this.props.sortBy])}
				})
				//Sort Data first
				if(this.props.sortOrder==='DESC') {
					publications.sort((a,b)=>{return b[this.props.sortBy].localeCompare(a[this.props.sortBy])})
				}else{
					publications.sort((a,b)=>{return a[this.props.sortBy].localeCompare(b[this.props.sortBy])})
				}
				//Commit
				this.setState({publications: publications})
			}});
		}
	}


	types() {
		return {
			1: 'Journals',
			2: 'Peer-Reviewed Conference Proceedings',
			3: 'Theses',
			4: 'Technical Reports',
			5: 'Technical Guides',
			6: 'Presentations',
			7: 'Webinars',
			8: 'Professional Publications'
		}
	}


	render() {
		return (
			<>
				<ContentHeader
					dateTime={new Date()}
					title={this.props.t('title')}
					subtitle={this.props.t('subtitle')}
				/>
				<div className="reading">
					<p className="ps_pop"><Trans t={this.props.t}>BibLaTeX citations and manuscript copies are available for my publications. Check the <i>Details</i> section under each listing for these and more information about each publication.</Trans></p>
				</div>

				{!this.state.publications ?
					<>
						<ContentPlaceholder />
						<ContentPlaceholder />
						<ContentPlaceholder />
						<ContentPlaceholder style={{'width': '35%'}}/>
					</>
				:
					Object.entries(this.types()).map((type) => {
						var publications = this.state.publications.filter(x => x.type===parseInt(type[0]));
						if(publications.length === 0) {return '';}
						else{
							return (
								<div className="reading ps_pop" key={type[0]}>
									<h1 id={this.parametrize(type[1])} className="ps_pop" style={{marginTop: '20px'}}>{this.props.t(type[1])}</h1>
									<ul className="ps_pop">
										{publications.map((publication, pIx) => {
											let bibtex = parseBibFile(publication.bibtex).entries_raw[0];
											//Parse authors
											let authors = normalizeFieldValue(bibtex.fields.author).split(' and ').map((author)=>{
												if(author.indexOf(', ') > -1) {
													let author_ = author.split(',')
													author_[0] = author_[0].toUpperCase()+','
													return author_.join(' ')
												}else{
													try{
														let author_ = author.split(' ')
														author_.unshift(author_.splice(author_.length-1, 1)[0])
														author_[0] = author_[0].toUpperCase()+','
														return author_.join(' ')
													}catch{
														return author
													}
												}
											})
											return (
											<li id={bibtex._id} key={'publication'+pIx}>
												{authors.join(', ').split(this.props.highlightAuthor).flatMap((item) => [item, <b key={item}>{this.props.highlightAuthor}</b>]).slice(0, -1)}
												{' '}
												({normalizeFieldValue(bibtex.fields.year)})
												{' '}
												<a href={publication.urlPub} target="_blank" rel="noreferrer"><i>{normalizeFieldValue(bibtex.fields.title)}</i></a>
												{bibtex.fields.journal ? ', '+normalizeFieldValue(bibtex.fields.journal) : ''}
												{bibtex.fields.publisher ? ', '+normalizeFieldValue(bibtex.fields.publisher) : ''}
												{bibtex.fields.volume ? ', vol. '+normalizeFieldValue(bibtex.fields.volume) : ''}
												{bibtex.fields.pages ? ', pp. '+normalizeFieldValue(bibtex.fields.pages) : ''}
												<details className="pub_listing pub_listing_small">
													<summary><span className="pub_listing_details">{publication.upload && <FontAwesomeIcon icon={solid('file-pdf')} />} <Trans t={this.props.t}>Details</Trans></span></summary>
													<span className="ps_tree_listing"><Trans t={this.props.t}>Publication language: </Trans><b>{publication.language}</b></span>
													{publication.upload && 
														<span className="ps_tree_listing"><a
															href={publication.upload.url}
															download={publication.upload.name}
															target="_blank"
															rel="noopener noreferrer"
														>
															<FontAwesomeIcon icon={solid('file-pdf')} /> <Trans t={this.props.t} values={{docSize: this.humanizeFileSize(publication.upload.size)}}>Download original manuscript (pdf, __docSize__)</Trans>
														</a></span>
													}
													{publication.details && 
														publication.details.split(/\r?\n/).map((detail_line)=> {
															let line = detail_line.replace(/\s/g,'')
															if(line.length > 0) {
																return <span key={detail_line} className="ps_tree_listing"><Markdown>{detail_line}</Markdown></span>
															}else{
																return ''
															}
														})
													}
													<span className="ps_tree_listing fake_link" onClick={() => {document.getElementById("bibtex_for_"+bibtex._id).classList.toggle('slideOpen')}}><Trans t={this.props.t}>Get BibLaTeX reference</Trans></span>
													<textarea id={"bibtex_for_"+bibtex._id} className="textarea_plain_text bibtex slideOpen_default" cols="100" rows="10" defaultValue={publication.bibtex} />
												</details>
											</li>)
										})}
									</ul>
								</div>
							)
						}
					})
				}
				
			</>
		);
	}
})
