import React  from 'react';
import { LineFormField, API } from 'psCMS-common';
import { withTranslation } from 'react-i18next';
const GoogleReCaptchaProvider = React.lazy(() => import('react-google-recaptcha-v3').then(module=>({default:module.GoogleReCaptchaProvider})));
const GoogleReCaptcha = React.lazy(() => import('react-google-recaptcha-v3').then(module=>({default:module.GoogleReCaptcha})));

var __reCaptchaToken__ = null // Settings this as a global ensures that the token is only ever set once per CRA instancee.

export default withTranslation('contact')(class Contact extends API {
	formFwdReference = React.createRef();


	getCommonState(props) {
		return {
			...super.getCommonState(props),
			name: '',
			email: '',
			body: '',
			messageSent: false,
			reCaptchaToken: __reCaptchaToken__
		}
	}


	setReCaptchaToken(reCaptchaToken) {
		__reCaptchaToken__ = reCaptchaToken;
		this.setState({reCaptchaToken:reCaptchaToken})
	}


	submit() {
		if(!this.formFwdReference.current.reportValidity()) {return false;}
		window.clearNotifications();
		const { name, email, body, reCaptchaToken } = this.state
		this.api('/contact', 'POST', {onSuccess:(data) => {
			this.setState({messageSent:true})
			window.addNotification('success', this.props.t('Message sent!'))
		}, onError: ()=>{
			if(this.state.status===422) {
				window.addNotification('error', this.props.t("Something's not right with the form data."))
			}else{
				window.addNotification('error', this.props.t("Hmm. Something unexpected happened."))
			}
		}, body:{ name, email, body, reCaptchaToken }})
	}


	render() {
		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
				language={this.props.i18n.language}
				useRecaptchaNet={true}
				useEnterprise={true}
				scriptProps={{
					async: false, // optional, default to false,
					defer: false, // optional, default to false
					appendTo: 'head', // optional, default to "head", can be "head" or "body",
					nonce: undefined // optional, default undefined
				}}>
				{!this.state.messageSent && 
					<form className="ps_lineForm ps_pop" ref={this.formFwdReference}>
						<p className="ps_lineForm_header ps_pop">{this.props.t('Message me')}</p>
						<input type="hidden" name="terms_accept" value="1" />
						<input type="hidden" name="c1" value="contact" />
						<LineFormField
							id={"name"}
							value={this.state.name}
							required={true}
							maxLength={100}
							onChange={(e)=>{this.setState({name: e.target.value})}}
							description={this.props.t('Your name')}
						/>
						<LineFormField
							id={"email"}
							type={"email"}
							value={this.state.email}
							required={true}
							maxLength={100}
							onChange={(e)=>{this.setState({email: e.target.value})}}
							description={this.props.t('Your E-mail')}
						/>
						<LineFormField
							id={"body"}
							type={"textarea"}
							value={this.state.body}
							required={true}
							maxLength={1000}
							onChange={(e)=>{this.setState({body: e.target.value})}}
							cols={90}
							rows={7}
							spellCheck={true}
							description={this.props.t('Enter your message here')}
						/>
						{this.state.reCaptchaToken===null ?
							<GoogleReCaptcha 
								onVerify={this.setReCaptchaToken.bind(this)}
							/>
						: '' }
						<div>
							<button
								onClick={()=>{this.submit()}}
								className={(this.state.isLoading===true ? ' pending' : '')}
								disabled={this.state.reCaptchaToken===null || this.state.isLoading===true ? 'disabled' : ''}>
								{this.props.t('Submit')}
							</button>
						</div>
					</form>
				}
				</GoogleReCaptchaProvider>
		);
	}
})