import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export default class InlineIsLoadingIcon extends React.Component {
	
	render(){
		const { actionWord = 'Loading', errorBody={}, isLoading, status, ...props } = this.props

		if(status===401) {
			return (
				<span 
					{...props}
					className={'loadingIcon active'} >
					<OverlayTrigger
						placement="bottom"
						overlay={(props) => (
							<Tooltip {...props}>
								Unauthorized. Please login first.
							</Tooltip>
						)}>
						<FontAwesomeIcon icon={solid('id-card')} className={'error'} />
					</OverlayTrigger>
				</span>
			)
		}else if(isLoading==="duplicate" || (isLoading==="error" && status==409)) {
			return (
				<span 
					{...props}
					className={'loadingIcon active'} >
					<OverlayTrigger
						placement="bottom"
						overlay={(props) => (
							<Tooltip {...props}>
								Value for {errorBody?.duplicateField} must be unique.
							</Tooltip>
						)}>
						<FontAwesomeIcon icon={solid('clone')} className={'error'} />
					</OverlayTrigger>
				</span>
			)
		}else if(isLoading==="invalid" || (isLoading==="error" && status==400)) {
			return (
				<span 
					{...props}
					className={'loadingIcon active'} >
					<OverlayTrigger
						placement="bottom"
						overlay={(props) => (
							<Tooltip {...props}>
								{descriptor} value is invalid. Please resolve the issue before proceeding.
							</Tooltip>
						)}>
						<FontAwesomeIcon icon={solid('asterisk')} className={'error'} />
					</OverlayTrigger>
				</span>
			)
		}else if(isLoading==="error") {
			return (
				<span 
					{...props}
					className={'loadingIcon active'} >
					<OverlayTrigger
						placement="bottom"
						overlay={(props) => (
							<Tooltip {...props}>
								There was a problem {actionWord} data on the server. Please try again.
							</Tooltip>
						)}>
						<FontAwesomeIcon icon={solid('triangle-exclamation')} className={'warning'} />
					</OverlayTrigger>
				</span>
			)
		}else if(isLoading===true) {
			return (
				<span 
					{...props}
					className={'loadingIcon active'} >
					<OverlayTrigger
						placement="bottom"
						overlay={(props) => (
							<Tooltip {...props}>
								{actionWord} data ...
							</Tooltip>
						)}>
						<FontAwesomeIcon icon={solid('sync')} className={'fa-spin'} />
					</OverlayTrigger>
				</span>
			)
		}else if(isLoading===false) {
			return (
				<span 
					{...props}
					className={'loadingIcon fadeOut'} >
					<FontAwesomeIcon icon={solid('sync')} className={'fa-spin'} />
				</span>
			)
		}
	}

}
