import React  from 'react';
import { LayoutCore, Header, API, withRouter } from 'psCMS-common';
import Blurb from './blurb';
import CometGenerator from './cometGenerator';
import SnowGenerator from './snowGenerator';
import Shortcuts from './shortcuts';
import { PSCMS } from './../../index';

export default withRouter(class Landing extends LayoutCore(API) {
	
	componentDidMount() {
		//When the page loads, do two things: attempt to grab user data (if logged in), and log this page server side
		this.api('/hits/log', 'POST', {onSuccess:(data) => {this.setState({user:data});}, body:{firstVisit: true, path:this.props.router.location.pathname, lang:this.props.router.params.lang}});
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
			this.api('/hits/log', 'POST', {body:{path:this.props.router.location.pathname, lang:this.props.router.params.lang}});
		}
	}

	logout() {
		this.api('/logout', 'GET', {onSuccess:() => {this.setState({user:null});}});
	}


	render() {
		return (
			<>
				{new Date().getMonth()!==11 ?
					<CometGenerator />
				:
					<SnowGenerator />
				}
				<div className="ps_wrapper">
					<Header
						variety={'landing'}
						localGroup={'public'}
						loggedIn={!!this.state.user}
						logout={this.logout.bind(this)}
					/>
					<Blurb />
					<Shortcuts />
					<footer className="landing_footer">
						<br />
						(c) paulstaubin.ca 2010-{new Date().getFullYear()}, {new PSCMS().name} {new PSCMS().softwareVersion}
					</footer>
				</div>
			</>
		)
	}
})
