import React from 'react';
import ContentLayout from './layout.js';
import { ContentHeader, CodeBlock } from 'psCMS-common';
import { withTranslation } from 'react-i18next';
const QRCodeSVG = React.lazy(() => import('qrcode.react').then(module=>({default:module.QRCodeSVG})));

export default withTranslation('digitalsignatures')(class DigitalSignatures extends ContentLayout {

	renderContent(){
		return (
			<>
				<ContentHeader
					dateTime={new Date(this.props.t('up_date_time'))}
					title={this.props.t('title')}
					subtitle={this.props.t('subtitle')}
				/>
				<div className="reading">
				<h1 className="ps_pop">{this.props.t('PGP')}</h1>

					<CodeBlock
						title={this.props.t('PGP Public Key')}
						customClasses={'ps_pop'}
						showStats={true}
						content={`-----BEGIN PGP PUBLIC KEY BLOCK-----
Version: GnuPG v2.0.22 (MingW32)
mQGNBFNIQ5gBDADOPaq1QTOG+yqM2xz1gBl4sVp/HO2ZBVXPheQc3JHBztfZimEK
oEL9Gi8Q6KTszFziLntokqZn8YWGcbPjdwNAg1LoRI5TbnlMtdD0VYPxfK6CPPwJ
R+280tccpIhMf33GFJZ4S6YdTYM2mOzxGcOnwtQP5UjjXN7NuSzj5eUdF6hbHLpX
5uyfBpkH5QvtBQ6ANBg+OCWtNNf/RO5hquomrNQANmpUeeDjH1FZLLr3OWY3I1sK
OYWKwShSrIcVGTP4e6tjwMRjYnzsNh4oG3MZSxLrrOMH+zo9fxwm5MCcgn7JI5wN
B13HBe2Msnh684BnSbGVR1STmy73EjXAVjWwruqCFI0M2ix4cvAVyFKQKP0G0ZH+
4Mn7o+RkxtaFqH2JMzVjI/j1BQlOoHGsXmjhyT6AzgUeCpYBxgEl51GcXcPlRBOw
SCACKvyTHDVAwjJF42eIfBd7omQRYWpFeM2hfnp0gY/1MGVlSneZWHzTUKz2eViU
WnZEqg0I93DYo/sAEQEAAbQsUGF1bCBTdC1BdWJpbiA8cGF1bC5zdC1hdWJpbkBt
YWlsLm1jZ2lsbC5jYT6JAbkEEwECACMFAlNIQ5gCGw8HCwkIBwMCAQYVCAIJCgsE
FgIDAQIeAQIXgAAKCRDkiePx1kxPOl2xDADAsdaPhYHc8dz5a9VTpvps69DHBOyR
1b2bw99WtcQPdepSx/opA3877f7f9mwvao+vw1rxYD1Z7RkyO/EK8rLyakSDZPM7
SEQNDPNXYEgXu8feUNC4dgOzf3noOW4lzyVK5kmAZVzOk8iBDtxHboJkjSaSUYLR
jlod8RQMDknUOP95ZOjndarsR9p0sZsmzybiq0FLoZRxccJlEuH894jY2a5gSkqg
L8c4DvGKwwp2dC54IPgHSsNMS/2Ix/k8gCLxPgrWDy3ys1P+0bYVpiPvpMVuRVT9
J0ovku/qiQW2Fvls5qwe7fsXKSnxSuJJTZrOSvGzo3ZkaNUyMK8dPd3AkO8Sj/M3
qx0KjPFmxnEos32lKQBPrsHVWyNcHp+i+jVrNFV5wW/JXKYa0ZR9LnblzOXuyQux
tixYP3JG51MgD0/MFmbxHFQ6GE6/cm1JmGFYBUpGaH0FoyPP4s4m0yFyiYmF4czW
LFzroPvIuT/cNtbTOUS2tSze1DxFIVVzClM=
=C6aP
-----END PGP PUBLIC KEY BLOCK-----`}/>

					<h1 className="ps_pop">Bitcoin</h1>
					<QRCodeSVG value={'bitcoin:1QH2vra7NLN63XN2rZPKmLLYeBAbXPPbQL?label=Public%20Hot'} height="256" width="256" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', float:'center', padding: '20px'}} className="ps_pop" alt="Bitcoin"/>
				</div>
			</>
		);
	}

})
