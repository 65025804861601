import React  from 'react';
import Header from './header';
import Footer from  './footer';
import LayoutCore from './concerns/LayoutCore'
import ContentPlaceholder from '../basics/contentPlaceholder.js'
import Notification from '../basics/notification.js'
import Basic from '../../components/basic'
import { withRouter } from '../../components/hocs';


export default withRouter(class BaseLayout extends LayoutCore(Basic) {


	render() {
		return (
			<React.Fragment>
				<Header {...this.props} />
				<main className={this.props.customClass!=undefined ? this.props.customClass : ''}>
					<div className="blank_content">
						<div id="notification-area" className="notification-area">
							{this.state.notifications.map(notification => (
								<Notification
									key={this.generateUID()}
									{...notification}>
									{notification.msg}
								</Notification>
							))}
						</div>
						<React.Suspense fallback={<ContentPlaceholder variety='paragraph'/>}>
							{this.props.children}
						</React.Suspense>
					</div>
				</main>
				<Footer {...this.props} />
			</React.Fragment>
		);
	}
})
