import React  from 'react';
import API from '../../components/api.js'
import AutocompleteText from './autocompleteText.js'
import InlineIsLoadingIcon from '../basics/inlineIsLoadingIcon.js'
import { WithContext as ReactTags } from 'react-tag-input';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';


export default class DynamicFormField extends API {
	inactivityTimoutId = null;

	static defaultProps = {
		inactivityTime: 1500,
		suggestions: [], // For ReactTags Primarily
		extraValidation: (value)=>{return true},
	};


	constructor(props) {
		super(props);
		value = (props.value===undefined || props.value===null ? (props.type=='tags' ? [] : '') : props.value)
		this.state = {
			...this.getCommonState(props),
			value: value,
			isValid: true
		}
		this.lastValue = value;
	}


	componentDidUpdate(prevProps) {
		//manually update the state if the props it is derived from is updated; react won't do this on it's own (and I guess that makes sense)
		if (prevProps.value !== this.props.value) {
			this.setState({value: this.props.value})
		}
	}


	updateField(e) {
		this.updateFromValue(e.target.value, e.target)
	}


	updateFromValue(value, target=null) {
		if(this.lastValue==value || !this.props.apiEndPoint) {
			this.setState({isLoading:null});
			return false
		}else{
			if(target && !target.checkValidity()) {
				this.setState({isLoading:'invalid'});
				return false
			}else{}
		}
		this.setState({value: (this.props.type==='date' ? (value==='' ? null : value) : value)}, this.update)
		window.clearTimeout(this.inactivityTimoutId);
	}


	update() {
		const validation = this.props.extraValidation(this.state.value)
		if(validation!==true) {
			this.setState({isLoading: 'invalid'})
			return false
		}
		if(this.props.apiEndPoint) {
			this.api(
				this.props.apiEndPoint,
				'PUT', {
				onSuccess:(data) => {
					this.lastValue = this.state.value
					if(this.props.type==='tags' && data.newTagCreated===true && this.props.onTagCreatedCallback) {
						this.props.onTagCreatedCallback()
					}
				},
				body: {
					[this.props.apiKey]: this.state.value
				}}
			)
		}
		this.props.changeCallback(this.state.value)
	}


	checkForInactivity(e) {
		this.checkForInactivityFromValue(e.target.value, e.target)
	};


	checkForInactivityFromValue(value, target=null) {
		this.setState({value: value})
		this.lastUpdate = Date.now();
		window.clearTimeout(this.inactivityTimoutId);
		this.inactivityTimoutId = setTimeout(() => {
			if (Date.now() - this.lastUpdate > 500) {
				this.updateFromValue(value, target);
			}
		}, this.props.inactivityTime);
	}


	renderInput() {
		const { apiKey, apiEndPoint, children, changeCallback, extraValidation, label, inactivityTime, unitSymbol='', ...props } = this.props;

		switch (this.props.type) {
			case 'textarea':
				return (
					<Form.Control
						{...(label ? {placeholder: label}: {})}
						{...props}
						as="textarea"
						style={{...(this.props.style ? this.props.style : {}), height: (this.props.rows ? this.props.rows : 4)*18+'px'}}
						onChange={this.checkForInactivity.bind(this)}
						value={this.state.value===null ? '' : this.state.value}
						onBlur={this.updateField.bind(this)}
					/>
				)
			case 'date':
				let DatePicker = this.props.datePicker //unconventional, but installing react-ease-picker in common doesn't seem to be possible right now.
				return (
					<DatePicker
						className="form-control"
						date={this.props.value}
						firstDay={0}
						css={'select {background-color: var(--color-bg-default) !important;color: var(--color-fg-default) !important;}'}
						onSelect={(date) => {
							this.setState({value: date}, this.update)
						}}
					/>
				)
			case 'select':
				return (
					<select
						{...(label ? {placeholder: label}: {})}
						{...props}
						className="form-select"
						value={this.state.value}
						onChange={this.updateField.bind(this)}>
						{children}
					</select>
				)
			case 'tags':

				const KeyCodes = {
					comma: 188,
					enter: 13,
					space: 32,
					tab: 9
				};

				return (
					<ReactTags
						autofocus    = {false}
						tags         = {this.state.value.map((val)=>{val.id = val.id.toString(); return val})} // Enforce ids as strings
						suggestions  = {this.props.suggestions.map((val)=>{return {id: val.id.toString(), text: val.text}})} // Enforce ids as strings
						delimiters   = {[KeyCodes.comma, KeyCodes.enter, KeyCodes.tab]}
						handleDelete = {(i) => {
							this.setState(
								{value: this.state.value.filter((tag, index) => index !== i)},
								this.update
								);
						}}
						handleAddition={(tag) => {
							this.setState(
								{value: [...this.state.value, tag]},
								this.update
								);
						}}
						handleDrag={(tag, currPos, newPos) => {
							const newTags = this.state.value.slice();
							newTags.splice(currPos, 1);
							newTags.splice(newPos, 0, tag);
							this.setState({value: newTags});
						}}
						handleTagClick={(index) => {
							if(this.props.onClickCallback) {this.props.onClickCallback(this.state.value[index]);}
							//console.log('The tag at index ' + index + ' was clicked');
						}}
						inputFieldPosition="bottom"
						autocomplete
						classNames={{
							tags: 'tags-container',
							tagInput: 'tags-input',
							tagInputField: 'form-control',
							selected: 'tags-selected',
							tag: 'tag',
							remove: 'btn-close',
							suggestions: 'tags-suggestions',
							activeSuggestion: 'activeSuggestionClass',
							editTagInput: 'editTagInputClass',
							editTagInputField: 'editTagInputField',
							clearAll: 'clearAllClass',
						}}
					/>
				)
			case 'range':
				return (
					<Form.Range
						{...(label ? {placeholder: label}: {})}
						{...props}
						type="range"
						value={this.state.value}
						onBlur={this.updateField.bind(this)}
						onChange={this.checkForInactivity.bind(this)}
					/>
				)
			case 'autocompletetext':
				return (
					<AutocompleteText
						{...(label ? {label: label, placeholder: label}: {})}
						{...props}
						className="form-control"
						value={this.state.value}
						onBlur={this.updateFromValue.bind(this)}
						onChange={this.checkForInactivityFromValue.bind(this)}
					/>
				)
			default:
				return (
					<Form.Control 
						{...(label ? {placeholder: label}: {})}
						{...props}
						type={this.props.type ? this.props.type : "text"}
						value={this.state.value}
						onBlur={this.updateField.bind(this)}
						onChange={this.checkForInactivity.bind(this)}
					/>
				)
			
		}
	}

	renderFormFieldWithLabel() {
		if(this.props.label) {
			if(this.props.type==='range'){
				return <React.Fragment>
					<Form.Label style={{margin:'10px 0 0'}}>{this.props.label} <Badge bg="secondary">{this.state.value}{this.props.unitSymbol ? this.props.unitSymbol : ''}</Badge></Form.Label>
					{this.renderInput()}
				</React.Fragment>
			}else if(this.props.type==='autocompletetext'){
				return this.renderInput()
			}else{
				return <FloatingLabel controlId={'floating'+this.props.label} label={this.props.label}>
					{this.renderInput()}
				</FloatingLabel>
			}
		}else{
			return this.renderInput()
		}

	}


	render(){
		return (
			<div style={{'position':'relative'}}>
				{this.renderFormFieldWithLabel()}
				<InlineIsLoadingIcon {...this.getAPIStatusProps()}
					actionWord="Saving"
					style={{position:'absolute', right:(this.props.type==='autocompletetext' ? '50px' : '10px'), 'top':(this.props.type==='textarea' ? '20px' : '50%'), transform:'translateY(-50%)' }}
				/>
			</div>
		)
	}
}
