import React  from 'react';
import { withTranslation } from 'react-i18next';
import { LineFormField, API } from 'psCMS-common';

export default withTranslation('common')(class Login extends API {
	formFwdReference = React.createRef();

	getCommonState(props) {
		return {
			...super.getCommonState(props),
			username: '',
			password: '',
		}
	}


	submit() {
		if(!this.formFwdReference.current.reportValidity()) {return false;}
		window.clearNotifications();
		this.api('/login', 'POST', {onSuccess:(data) => {
			window.location.href = data.location
		}, onError: ()=>{
			if(this.state.status===401) {
				window.addNotification('error', this.props.t("Login information refused."))
			}else{
				window.addNotification('error', this.props.t("Hmm. Something unexpected happened."))
			}
		}, body:this.state})
	}


	render(){
		return (
			<form className="ps_lineForm ps_pop reduced" ref={this.formFwdReference}>
				<p className="ps_lineForm_header ps_pop reduced">{this.props.t('Login')}</p>
				<LineFormField
					id={"username"}
					name={"username"}
					value={this.state.username}
					size={"reduced"}
					required={true}
					onChange={(e)=>{this.setState({username: e.target.value})}}
					description={this.props.t('Username or e-mail')}
				/>
				<LineFormField
					id={"password"}
					name={"password"}
					type={"password"}
					value={this.state.password}
					size={"reduced"}
					required={true}
					onChange={(e)=>{this.setState({password: e.target.value})}}
					description={this.props.t('Password')}
				/>
				<div>
					<button onClick={()=>{this.submit()}} className={(this.state.isLoading===true ? ' pending' : '')} disabled={this.state.isLoading===true} >{this.props.t('Submit')}</button>
					{' '}
					<button style={{maxWidth:'320px'}} formNoValidate="" >{this.props.t('Recover password')}</button>
				</div>
			</form>
		);
	}
})
