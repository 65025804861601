export default (superclass) => class LayoutCore extends superclass {


	constructor(props) {
		super(props)
		this.state = {
			...super.state,
			notifications: []
		}
		window.addNotification = this.addNotification.bind(this)
		window.clearNotifications = this.clearNotifications.bind(this)
	}


	componentDidUpdate(prevProps, prevState) {
		//Cleanup notifications if route changes
		if (prevProps.router.location !== this.props.router.location) {
			this.clearNotifications();
		}
	}


	addNotification(type, msg, link, link_text) {
		this.setState({notifications: [...this.state.notifications, {type: type, msg: msg, link:link, link_text:link_text}]});
	}


	clearNotifications() {
		this.setState({notifications: []});
	}




}