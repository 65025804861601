import React  from 'react';
import Basic from './basic';

export default class API extends Basic {
	

	constructor(props) {
		super(props);
		this.state = {
			...this.getCommonState(props),
		}
	}


	getCommonState(props) {
		//Placeholder. Expand this object to add state variables to be initiated
		return {
			isLoading: null,
			status: null,
			errorBody: {}
		}
	}


	getAPIStatusProps() {
		return { isLoading: this.state.isLoading, status: this.state.status, errorBody: this.state.errorBody }
	}

	
	api(apiURL, method, {onSuccess=(data)=>{}, onError=()=>{}, body=null, silently=false}) {
		if(!silently) {this.setState({isLoading: true})}
		fetch(apiURL, {
			method: method,
			headers: { 'Content-Type': 'application/json' },
			...(body!=null ? {body:JSON.stringify(body)} : {})
		})
		.then(async res => {
			//Sometimes the body contains relevant error messsages, sometimes it doesn't
			let data
			try { 
				data = await res.json()
			}catch{
				data = res.statusText 
			}
			if(res.status >= 400) {
				console.error(`Server responded with error ${res.status}!`);
				this.setState({status: res.status, ...(!silently ? {isLoading: 'error', errorBody: data} : {})}, ()=>{
					onError();
				});
			}else{
				this.setState({status: res.status, ...(!silently ? {isLoading: false} : {})}, ()=>{
					onSuccess(data);
				});
			}
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components
		err => {
			console.log(err)
		});
	}


}
